import config from './config/certcenter'
import axios from '@/utils/request'

export const uploadCertExcel = params =>
  axios.post(`/course/admin/certPrivateAppointCont/import/excel`, params) //上传文件

export const selectType = params => axios.post(config.selectType, params)
export const certificateList = params => axios.post(config.certificateList, params)
export const createdCertificate = params => axios.post(config.createdCertificate, params)
export const certificatelExport = params =>
  axios.post(config.certificatelExport, params, { responseType: 'blob' })
export const delCertificate = params => axios.post(config.delCertificate, params)
export const beforeDelCertificate = params => axios.post(config.beforeDelCertificate, params)

export const uploadData = params => axios.post(config.uploadData, params)
export const examine = params => axios.post(config.examine, params)
export const getClassStudy = params => axios.post(config.getClassStudy, params)
export const getResult = params => axios.post(config.getResult, params)

export const downloadDataExport = params =>
  axios.post(config.downloadDataExport, params, { responseType: 'blob' })
export const downloadClassStudyInfoExport = params =>
  axios.post(config.downloadClassStudyInfoExport, params, { responseType: 'blob' })
export const downloadGetClassStudy = params =>
  axios.post(config.downloadGetClassStudy, params, { responseType: 'blob' })
export const downloadTempate = params =>
  axios.post(config.downloadTempate, params, { responseType: 'blob' })

export const getClassStudyInfo = params => axios.post(config.getClassStudyInfo, params)

export const certificateDetail = params => axios.post(config.certificateDetail, params)
export const attrSelect = params => axios.post(config.attrSelect, params)
export const classStaffList = params => axios.post(config.classStaffList, params)
export const classSubmit = params => axios.post(config.classSubmit, params)
export const updatePublishStatus = params => axios.post(config.updatePublishStatus, params)
export const getCerEnrollList = params => axios.post(config.cerEnrollList, params)
export const getCerEnrollInfo = params => axios.post(config.cerEnrollInfo, params)
export const getPageList = params => axios.post(config.getPageList, params)
export const getPageInfo = params => axios.post(config.getPageInfo, params)
export const courseGetByBuyList = params => axios.post(config.courseGetByBuyList, params)
export const courseGetBySelfList = params => axios.post(config.courseGetBySelfList, params)
export const courseAddSave = params => axios.post(config.courseAddSave, params)
export const courseDelete = params => axios.post(config.courseDelete, params)
export const certQuestionsList = params => axios.post(config.certQuestionsList, params)
export const certQuestionsSave = params => axios.post(config.certQuestionsSave, params)
export const certQuestionsPageList = params => axios.post(config.certQuestionsPageList, params)
export const certQuestionsDelete = params => axios.post(config.certQuestionsDelete, params)
export const classCertEndTrain = params => axios.post(config.classCertEndTrain, params)
export const cerEnrollEndTrain = params =>
  axios.post(config.cerEnrollEndTrain, params, { responseType: 'blob' })

//证书培训管理
export const classCertQuery = params => axios.post(config.classCertQuery, params)
export const classCertExport = params =>
  axios.post(config.classCertExport, params, { responseType: 'blob' })
export const classCertDelete = params => axios.post(config.classCertDelete, params)
export const classCertCreate = params => axios.post(config.classCertCreate, params)
export const certOrgQuery = params => axios.post(config.certOrgQuery, params)
export const certDropDownList = params => axios.post(config.certDropDownList, params)
//add
export const classCertBaseEdit = params => axios.post(config.classCertBaseEdit, params)
export const classCertBaseInfo = params => axios.post(config.classCertBaseInfo, params)
// export const certAttrProduct = params => axios.post(config.certAttrProduct, params)
//证书类目
export const productCertCategory = params => axios.post(config.productCertCategory, params)
export const listReviewByCategory = params => axios.post(config.listReviewByCategory, params)
//components
export const classCertStaff = params => axios.post(config.classCertStaff, params)
export const classCertStudent = params => axios.post(config.classCertStudent, params)
export const classCertSignQuery = params => axios.post(config.classCertSignQuery, params)
export const classCertStudentAdd = params => axios.post(config.classCertStudentAdd, params)
export const classCertStudentDelete = params => axios.post(config.classCertStudentDelete, params)
export const classCertCourseStore = params => axios.post(config.classCertCourseStore, params)
export const classCertPublish = params => axios.post(config.classCertPublish, params)
export const classCertCourseStoreDelete = params =>
  axios.post(config.classCertCourseStoreDelete, params)
export const classCertCourseQuestionStore = params =>
  axios.post(config.classCertCourseQuestionStore, params)
export const classCertCourseQuestionStoreAdd = params =>
  axios.post(config.classCertCourseQuestionStoreAdd, params)

export const classCertCourse = params => axios.post(config.classCertCourse, params)
export const classCertCourseDelete = params => axios.post(config.classCertCourseDelete, params)

export const classCertCourseShop = params => axios.post(config.classCertCourseShop, params)
export const classCertCourseShopAdd = params => axios.post(config.classCertCourseShopAdd, params)

export const classCertCoursePacket = params => axios.post(config.classCertCoursePacket, params)
export const classCertCoursePacketAdd = params =>
  axios.post(config.classCertCoursePacketAdd, params)

//ShowDia
export const certProductList = params => axios.post(config.certProductList, params)
export const selectCertList = params => axios.post(config.selectCertList, params)
export const selectOrgList = params => axios.post(config.selectOrgList, params)
export const channelList = params => axios.post(config.channelList, params)
export const submitChannel = params => axios.post(config.submitChannel, params)

export const courseTrainTypeUpdate = params => axios.post(config.courseTrainTypeUpdate, params)
export const certificateUpdate = params => axios.post(config.certificateUpdate, params)
