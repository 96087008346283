export default {
  certificateList: '/course/admin/certPrivate/getPage',
  createdCertificate: '/course/admin/certPrivate/save',
  selectType: '/course/product/attr/category/cert/options',

  certificateDetail: '/course/admin/certPrivate/getById',

  certificateUpdate: '/course/admin/certPrivate/update',

  delCertificate: '/course/admin/certPrivate/delete',

  certificatelExport: '/course/admin/certPrivate/downloadExcel', //证书管理导出
  beforeDelCertificate: '/course/supply/cert/remove/check',
  attrSelect: '/course/product/attr/category/cert/list',

  classCertQuery: '/course/admin/certPrivateTrain/getPage', // 证书班级查询
  classCertCreate: '/course/admin/certPrivateTrain/save', // 证书班级添加
  classCertBaseInfo: '/course/admin/certPrivateTrain/getById', // 证书班级基本信息查询
  classCertBaseEdit: '/course/admin/certPrivateTrain/update', // 证书班级基本信息编辑
  classCertDelete: '/course/admin/certPrivateTrain/delete', // 证书班级删除
  certDropDownList: '/course/admin/certPrivate/getDropDownList', // 查询企业下证书

  classCertExport: '/course/admin/certPrivateTrain/downloadExcel', // 证书培训班级导出

  classCertCourse: '/course/admin/CertPrivateTrainRelationCourse/getPage', // 查询证书班级绑定课程
  classCertPublish: '/course/admin/certPrivateTrain/confirmPublish', // 证书班级发布

  classCertEndTrain: '/course/admin/certPrivateTrain/endTrain', // 结训

  downloadDataExport: '/course/admin/certPrivateAppointCont/downloadData', //导出

  getResult: '/course/admin/certPrivateAppointCont/getResult', //完成 成绩

  downloadGetClassStudy: '/course/admin/certPrivateTrain/downloadGetClassStudy', // 学习统计导出学习记录
  downloadTempate: '/course/admin/certPrivateAppointCont/download/tempate', // 导入成绩与证书 下载导入模版

  getClassStudyInfo: '/course/admin/certPrivateTrain/getClassStudyInfo', // 学习详情
  downloadClassStudyInfoExport: '/course/admin/certPrivateTrain/downloadGetClassStudyInfo', // 导出学习详情记录

  //添加课程
  courseGetByBuyList: '/course/admin/CertPrivateTrainRelationCourse/getBuyCourse', // 已购课程
  courseGetBySelfList: '/course/admin/CertPrivateTrainRelationCourse/getHaveCourse', // 自建课程
  courseAddSave: '/course/admin/CertPrivateTrainRelationCourse/save', // 添加课程
  courseDelete: '/course/admin/CertPrivateTrainRelationCourse/delete', // 删除课程

  //配置题库
  certQuestionsPageList: '/course/admin/CertPrivateTrainRelationPaper/getPage', // 获取关联试卷信息分页
  certQuestionsList: '/course/admin/CertPrivateTrainRelationPaper/getPaper', // 获取试卷信息不包含已添加的试卷
  certQuestionsSave: '/course/admin/CertPrivateTrainRelationPaper/save', // 添加课程
  certQuestionsDelete: '/course/admin/CertPrivateTrainRelationPaper/delete', // 删除课程

  classCertCourseQuestionStore: '/course/identify/class/cert/course/question/store/query', // 证书班级查询题库列表
  classCertCourseQuestionStoreAdd: '/course/identify/class/cert/course/store/bound', // 批量绑定题库
  classCertCourseStore: '/course/identify/class/cert/course/class/store/query', // 查询证书班级绑定题库信息
  classCertCourseStoreDelete: '/course/identify/class/cert/course/store/delete', // 删除题库

  getClassStudy: '/course/admin/certPrivateTrain/getClassStudy', // 学习统计

  //指定报名
  classStaffList: '/course/admin/certPrivateAppointCont/getUmsStudents',
  classSubmit: '/course/admin/certPrivateTrainRelation/confirmAppoint',

  updatePublishStatus: '/course/admin/certPrivate/updatePublishStatus',

  //证书报名管理
  cerEnrollList: '/course/admin/certPrivateAppointCont/getAppointManagerPage',
  cerEnrollInfo: '/course/admin/certPrivateAppointCont/getByAppointId',

  cerEnrollEndTrain: '/course/admin/certPrivateAppointCont/downloadAppointList', // 证书报名管理-导出报名列表

  //证书管理 - 报名详情 - 查看详情记录
  getPageList: '/course/admin/certPrivateTrainRelation/getPage',
  //查看详情记录
  getPageInfo: '/course/admin/certPrivateAppointCont/getAppointInfoPage',

  examine: '/course/admin/certPrivateAppointCont/examineByAppointId',
  uploadData: '/course/admin/certPrivateAppointCont/saveOrUpdateAppointRecord',

  // certAttrProduct: '/course/admin/certPrivate/getPage', // 类目下的证书商品

  productCertCategory: '/course/product/attr/category/cert/options', //证书类目
  listReviewByCategory: '/course/supply/cert/listReviewByCategory', //类目下的证书（创建商品）

  classCertStudent: '/course/identify/class/cert/student/query', // 证书班级学员查询
  classCertSignQuery: '/course/identify/class/cert/sign/query', // 证书班级报名信息查询
  classCertStudentAdd: '/course/identify/class/cert/student/batch/add', // 批量添加学员
  classCertStudentDelete: '/course/identify/class/cert/student/batch/delete', // 批量删除学员
  classCertCourseDelete: '/course/identify/class/cert/course/delete', // 批量删除课程
  classCertStaff: '/course/identify/class/cert/staff/query', // 查询教务

  classCertCourseShop: '/course/manage/courses/cert/shoppingMall', // 开班-证书课程商城
  classCertCourseShopAdd: '/course/identify/class/cert/course/shop/add', // 通过课程商城加课
  classCertCoursePacket: '/course/identify/class/cert/packet/query', // 课程包查询
  classCertCoursePacketAdd: '/course/identify/class/cert/course/packet/add', // 通过课程包加课
  certOrgQuery: '/course/supply/cert/org/query', // 查询企业下证书

  certProductList: '/course/identify/class/cert/org/cert/product/list',
  selectCertList: '/course/identify/class/cert/cert/category/list',
  selectOrgList: '/course/identify/class/cert/org/cert/list',
  channelList: '/course/identify/class/cert/org/cert/display/channel/list',
  submitChannel: '/course/identify/class/cert/configure/display/channel',

  courseTrainTypeUpdate: '/course/identify/class/cert/course/train/type/update',
}
