<template>
  <div>
    <table-list
      class="tableList"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :title="tableTitle"
      :tabs-list="tabsList"
      :options="{ selection: true, operatesWidth: 180, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格tabs
const tabsList = [
  {
    prop: 'publishStatus',
    activeValue: '',
    children: [
      //状态，0.删除，1编辑中，2已发布
      { value: 1, label: '编辑中' },
      { value: 2, label: '已发布' },
      // { value: 0, label: '已删除' },
    ],
  },
]
//表格标题
const tableTitle = '证书列表'
const btns = _this => [
  {
    label: '添加证书',
    type: 'primary',
    method: _this.handleAdd,
  },
  {
    label: '导出',
    type: 'primary',
    method: _this.handleExport,
  },
  {
    label: '删除',
    method: _this.handleDel,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '证书名称',
    prop: 'certName',
  },
  {
    label: '证书分类',
    prop: 'productAttributeCategoryId',
    type: 'select',
    children: _this.selectTypeList.map(item => ({
      value: item.productAttributeCategoryId,
      label: item.name,
    })),
  },
  {
    label: '地区',
    type: 'treeSelect',
    prop: 'cityCodeList',
    options: _this.total,
    optionProps: {
      value: 'value',
      label: 'label',
      children: 'children',
    },
  },
  {
    label: '创建时间',
    prop: 'examTime',
    type: 'picker',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'certName',
    label: '证书名称',
    minWidth: '130px',
  },
  {
    prop: 'productAttributeCategoryName',
    label: '证书分类',
    minWidth: '134px',
    twoLines: true,
  },
  {
    prop: 'certLevelShow',
    label: '证书等级',
    minWidth: '150px',
  },
  {
    prop: 'cityStr',
    label: '证书归属地',
    minWidth: '136px',
    twoLines: true,
  },
  {
    prop: 'publishStatus',
    label: '证书状态',
    minWidth: '76px',
    formatter: row => {
      //状态，0.删除，1编辑中，2已发布
      if (row.publishStatus == 0) {
        return '已删除'
      } else if (row.publishStatus == 1) {
        return '编辑中'
      } else {
        return '已发布'
      }
    },
  },
  {
    prop: 'createTime',
    label: '创建时间',
    minWidth: '150px',
    sortable: 'sortable',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    isShow: item => item.publishStatus == 1,
    method: _this.handleIsEdit,
  },
  {
    label: '详情',
    isShow: item => item.publishStatus == 2,
    method: _this.handleEdit,
  },
  {
    label: '确定发布',
    isShow: item => item.publishStatus == 1,
    method: _this.onSubmit,
  },
  {
    label: '指定报名',
    isShow: item => item.publishStatus == 2,
    method: _this.handleAppoint,
  },
  {
    label: '报名详情',
    isShow: item => item.publishStatus == 0 || item.publishStatus == 2,
    method: _this.handleDetail,
  },
]
import total from '../../../assets/address/city'
import TableList from '@/components/TableList'
import {
  selectType,
  certificateList,
  certificatelExport,
  delCertificate,
  // beforeDelCertificate,
  updatePublishStatus,
} from '@/api/certCenter'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      btns,
      searchForm,
      columns,
      operates,
      tabsList,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      total,
      queryParams: {},
      select: [],
      list: [],
      selectTypeList: [],
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
    }
  },
  created() {
    this.selectTypeData()
    this.certificateList()
  },
  methods: {
    async certificateList() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(certificateList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list.map(item => ({
        ...item,
        cityStr: item.provinceName + '-' + item.cityName,
        // gradeListStr: '正在开发中...',
        certLevelShow: item.certLevelShow != null ? item.certLevelShow : '--',
        // gradeListStr: item.gradeList.length
        //   ? item.gradeList.map(item => item.value).join('、')
        //   : '—',
      }))
      this.pager.total = res.data.total
    },
    async selectTypeData() {
      const [res, err] = await to(selectType({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.selectTypeList = res.data
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      console.log(this.queryParams)
      if (queryParams.examTime && queryParams.examTime !== 0) {
        this.queryParams.beginCreateTime = queryParams.examTime[0]
        this.queryParams.endCreateTime = queryParams.examTime[1].split(' ')[0] + ' 23:59:59'
      } else {
        this.queryParams.beginCreateTime = ''
        this.queryParams.endCreateTime = ''
      }
      if (queryParams.cityCodeList) {
        this.queryParams.cityCode = queryParams.cityCodeList[1]
      } else {
        this.queryParams.cityCode = ''
      }
      this.certificateList()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    handleAdd() {
      this.$router.push('/certCenter/add')
    },
    async handleExport() {
      let certIdList = []
      this.select.map(v => certIdList.push(v.certId))
      const { current, size } = this.pager
      const [res, err] = await to(
        certificatelExport({ current, size, ...this.queryParams, certIds: certIdList }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '证书列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // async beforeDelCertificate() {
    //   let arr = []
    //   this.select.map(v => arr.push(v.certId))
    //   const [res, err] = await to(beforeDelCertificate({ certIdList: arr }))
    //   if (err) return this.$message.warning(err.msg)
    //   return res.data
    // },
    async handleDel() {
      if (!this.select.length) return this.$message.warning('请先选择要删除的数据')

      this.$confirm('您确定要删除所选证书吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let certId = []
        this.select.map(v => certId.push(v.certId))
        const [, err] = await to(delCertificate({ certIds: certId }))
        if (err) return this.$message({ type: 'error', message: err.msg })
        this.$message({ type: 'success', message: '删除成功' })
        this.certificateList()
      })
    },
    handleIsEdit(row) {
      this.$router.push(`/certCenter/add/${row.certId}/${0}`)
    },
    handleEdit(row) {
      this.$router.push(`/certCenter/add/${row.certId}/${1}`)
    },
    onSubmit(row) {
      console.log('123')
      this.onUpdatePublishStatus(row.certId)
    },
    handleAppoint(row) {
      this.$router.push(`/certCenter/appoint/${row.certId}`)
    },
    handleDetail(row) {
      this.$router.push({
        path: '/certCenter/detail',
        query: {
          certPrivateId: row.certId,
        },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.certificateList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.certificateList()
    },
    async onUpdatePublishStatus(certId) {
      this.$confirm('您确定要发布成所选证书吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(updatePublishStatus({ certId: certId }))
        if (err) return this.$message({ type: 'error', message: err.msg })
        this.$message({ type: 'success', message: '发布成功' })
        this.certificateList()
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
